import 'normalize.css';
import './global/index.scss';
import { createApp } from 'vue';
import router from './router';
import App from './App.vue';
import { VueReCaptcha } from "vue-recaptcha-v3";
import  VueGoogleMaps from '@fawmi/vue-google-maps';
import { createMetaManager } from 'vue-meta';
import { library } from '@fortawesome/fontawesome-svg-core';
import Toaster from "@meforma/vue-toaster";
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faCopyright, faCircleXmark, faEnvelope, faPause, faPlay, faStop, faVolumeLow, faVolumeHigh, faLocationDot, faHandPointRight, faHandPointLeft, faThumbsUp, faPhone } from '@fortawesome/free-solid-svg-icons';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';

library.add(faEnvelope, faLinkedin, faCopyright, faCircleXmark, faVolumeHigh, faStop, faPause, faPlay, faVolumeLow, faLocationDot, faHandPointRight, faHandPointLeft, faThumbsUp, faPhone)

const app = createApp(App);
app.use(router);
app.use(Toaster);
app.use(VueReCaptcha, { 
  siteKey: "6LckSkMjAAAAACDDjyo_1bChIQliJGJMZoUEJVHc", 
  loaderOptions: { 
    autoHideBadge: true 
  }
});
app.use(VueGoogleMaps, 
  { load: 
    { key: 'AIzaSyD-xAgKRfQfkYF9Y0lK3IBop938SbfhZlQ' }
  });
app.use(createMetaManager());
app.component('font-awesome-icon', FontAwesomeIcon);
app.mount('.app');
