<template>
  <metainfo>
  <template v-slot:title="{ content }">{{ content ? `${content} | The Sprinkler Guy LLC` : `The Sprinkler Guy LLC` }}</template>
  </metainfo>
  <Header v-if="isMounted" />
  <div class="main">   
      <div class="content">
            <router-view class="routerView" v-slot="{ Component, route }">
              <transition
                  mode="out-in"
                  v-on:enter="enterAnimation"
                  v-on:leave="leaveAnimation"
              >
              <div name="route" :key="route">
              <component :is="Component" />
              </div>
              </transition>
          </router-view>
      </div>
  </div>
  <Footer v-if="isMounted" />
</template>

<script>
const Header = defineAsyncComponent(() =>
  import('./components/Header.vue')
)
const Footer = defineAsyncComponent(() =>
  import('./components/Footer.vue')
)
import { useSmoke } from './transitions/smoke'
import { useMeta } from 'vue-meta'
import { defineAsyncComponent } from 'vue'
export default {
  data: () => ({
      isMounted: false
  }),
  components: {
  Header, 
  Footer
  },
  
  setup () {
    useMeta({
      title: '',
      htmlAttrs: { lang: 'en', amp: true }
    })
    const { enterAnimation, leaveAnimation } = useSmoke(); 
        return {
        enterAnimation, 
        leaveAnimation
    }
  },
  
  mounted() {
      setTimeout(() => this.isMounted = true, 1500)
  }, 
}
</script>

<style lang="scss" scoped>
@import './global/variables.scss';
.main {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background: $color-background;
  scroll-behavior: smooth;
  overflow: hidden;
}
.content {
  flex: 1 0;
  position: relative;
  margin: 20px 0 20px 0;
  height: 100vh;
}

</style>
