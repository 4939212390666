import { createRouter, createWebHashHistory } from 'vue-router';

const routes = [
    { path: '/', component: () => import('./pages/Home.vue')},
    { path: '/contact', component: () => import('./pages/Contact.vue')},
    { path: '/about', component: () => import('./pages/About.vue')},
    { path: '/services', component: () => import('./pages/Services.vue')},
    { path: '/gallery', component: () => import('./pages/Gallery.vue')},
    { path: '/reviews', component: () => import('./pages/Reviews.vue')},
    { path: '/map', component: () => import('./pages/Map.vue')},
    { path: '/terms', component: () => import('./pages/Terms.vue')},
    { path: '/privacy', component: () => import('./pages/Privacy.vue')},
    { path: '/faq', component: () => import('./pages/Faq.vue')}
];

const router = createRouter({    
    history: createWebHashHistory(),
    routes,
    scrollBehavior(to, from, savedPosition) {
        // always scroll to top
        return { top: 0 }
    },
});

export default router;
